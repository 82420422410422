import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import FeaturedCreatorTemplate from './FeaturedCreatorTemplate'

const FeaturedCreator = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title, position, awards, row }
    }
  } = data

  const templateData = { title, position, awards, row }

  return (
    <Layout title={title} description={position}>
      <FeaturedCreatorTemplate {...templateData} />
    </Layout>
  )
}

FeaturedCreator.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default FeaturedCreator

export const pageQuery = graphql`
  query FeaturedCreatorByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        position
        awards {
          awardsname
          awardsimage {
            id
            publicURL
          }
          awardscount
        }
        row
      }
    }
  }
`
